import { ActiveAccount } from '@/redux/activeAccount/activeAccount.slice';
import { UserRoles } from '@/types/user.type';

export interface ISidebarRoutes {
  id: string;
  translation: string;
  path: string;
  permissions: (UserRoles | '*')[];
  dropdownParent?: string;
}

export const getSidebarRoutes = (
  activeAccount: ActiveAccount | null,
): ISidebarRoutes[] => [
  {
    id: 'dashboardLink',
    translation: 'DASHBOARD',
    path: activeAccount
      ? `/app/dashboard?${
          activeAccount.type === 'partner'
            ? 'partner=' +
              activeAccount.id.toString() +
              '&partner-name=' +
              activeAccount.name
            : 'company=' +
              activeAccount.id.toString() +
              '&company-name=' +
              activeAccount.name
        }`
      : '/app/dashboard',
    permissions: ['*'],
  },
  {
    id: 'projectStatusLink',
    translation: 'PROJECT_STATUS',
    path: '/app/project-status',
    permissions: ['*'],
  },
  {
    id: 'sensorManagementLink',
    translation: 'SENSOR_MANAGEMENT',
    path: '/app/sensor-management',
    dropdownParent: 'STOCK_MANAGEMENT',
    permissions: [
      UserRoles.SUPER_ADMIN,
      UserRoles.COMPANY_ADMIN,
      UserRoles.PARTNER_ADMIN,
    ],
  },
  {
    id: 'relayManagementLink',
    translation: 'RELAY_MANAGEMENT',
    path: '/app/relay-management',
    dropdownParent: 'STOCK_MANAGEMENT',
    permissions: [
      UserRoles.SUPER_ADMIN,
      UserRoles.COMPANY_ADMIN,
      UserRoles.PARTNER_ADMIN,
    ],
  },
  {
    id: 'deliveryPerformanceSensorTechnicianLink',
    translation: 'DELIVERY_PERFORMANCE_ST',
    path: '/app/delivery-performance-sensor-technician',
    permissions: ['*'],
    dropdownParent: 'DELIVERY_PERFORMANCE',
  },
  {
    id: 'deliveryPerformanceLELink',
    translation: 'DELIVERY_PERFORMANCE_LE',
    path: '/app/delivery-performance-le',
    permissions: ['*'],
    dropdownParent: 'DELIVERY_PERFORMANCE',
  },
  {
    id: 'userManagementLink',
    translation: 'USERS',
    path: '/app/user-management',
    permissions: [
      UserRoles.SUPER_ADMIN,
      UserRoles.COMPANY_ADMIN,
      UserRoles.PARTNER_ADMIN,
    ],
  },
  {
    id: 'reportsLink',
    translation: 'REPORTS',
    path: '/app/reports',
    permissions: [],
  },
  {
    id: 'logsLink',
    translation: 'LOGS',
    path: '/app/logs',
    permissions: [
      UserRoles.SUPER_ADMIN,
      UserRoles.PARTNER_ADMIN,
      UserRoles.COMPANY_ADMIN,
    ],
  },
];
