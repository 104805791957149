import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  font-weight: bold;
  line-height: 35px;
  color: #fff;
  gap: 20px;
  flex-wrap: wrap;
  font-size: 12px;
`;

export const ListWrapper = styled.div`
  columns: 3 auto;
  column-gap: 1rem;
  width: 100%;
`;

export const ListObject = styled.span`
  font-weight: normal;
`;

export const MapContainer = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  background: white;
`;

export const ListContainer = styled.div`
  margin: 20px 0;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px;
`;

export const PipeSegments = styled.div`
  text-align: center;
  display: flex;
  align-items: start;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px;
`;

export const PipeSegment = styled.div`
  border: 2px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
`;

export const PipeSegmentText = styled.div`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
`;

export const ListTitle = styled.h3`
  text-decoration: underline;
  margin-bottom: 10px;
`;

export const ListTitleContainer = styled.div`
  margin-top: 20px;
`;

export const ListContainerItem = styled.div`
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
  color: white;
  margin: 0 1rem 1rem 0;
  display: inline-block;
  padding: 10px 0;
  width: 100%;
  text-align: center;
`;
