import StatusMessage from '@/components/StatusMessage/StatusMessage';
import * as Styled from './style';
import React, { FC } from 'react';
import useCustomTranslation from '@/localization/useCustomTranslation';

interface StatusWrapperProps {
  correlationOutcome:
    | 'detected'
    | 'Suspicious pipe info or its Out of bracket towards point A'
    | 'Suspicious pipe info or its Out of bracket towards point B'
    | 'Inconclusive'
    | 'Central position suspected'
    | 'Not found'
    | undefined
    | null;
}

export const StatusWrapper: FC<StatusWrapperProps> = ({
  correlationOutcome,
}) => {
  const { prefixedT } = useCustomTranslation('CORRELATION_INFO_PAGE.STATUS');
  return (
    <Styled.StatusWrapper>
      {correlationOutcome === 'detected' && (
        <StatusMessage
          status="success"
          title={prefixedT('SUCCESS.TITLE')}
          description={prefixedT('DETECTED')}
        />
      )}
      {correlationOutcome ===
        'Suspicious pipe info or its Out of bracket towards point A' && (
        <StatusMessage
          status="warning"
          title={prefixedT('WARNING.TITLE')}
          description={prefixedT('SUSPICIOUS_A')}
        />
      )}
      {correlationOutcome ===
        'Suspicious pipe info or its Out of bracket towards point B' && (
        <StatusMessage
          status="warning"
          title={prefixedT('WARNING.TITLE')}
          description={prefixedT('SUSPICIOUS_B')}
        />
      )}
      {correlationOutcome === 'Inconclusive' && (
        <StatusMessage
          status="warning"
          title={prefixedT('WARNING.TITLE')}
          description={prefixedT('INCONCLUSIVE')}
        />
      )}
      {correlationOutcome === 'Central position suspected' && (
        <StatusMessage
          status="warning"
          title={prefixedT('WARNING.TITLE')}
          description={prefixedT('CENTRAL')}
        />
      )}
      {correlationOutcome === 'Not found' && (
        <StatusMessage
          status="warning"
          title={prefixedT('WARNING.TITLE')}
          description={prefixedT('NOT_FOUND')}
        />
      )}
    </Styled.StatusWrapper>
  );
};
